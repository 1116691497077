import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { navigate } from 'gatsby'
import useStringBuilder from './use-string-builder-state'
import StringBuilderTemplate from './string-builder-template'
import Product from './product'

export default function StringBuilder4(props) {
	const { settings } = props.pageContext
	const addOns = settings.addOns || []
	const [state] = useStringBuilder()

	useEffect(() => {
		if (!state.string) {
			let prev = document.location.pathname.split(`/`)
			if (!prev[prev.length - 1]) {
				prev.pop()
			}
			prev.pop()
			navigate(prev.join(`/`))
		}
		else if (!state.strand1 || !state.strand2 || !state.serving) {
			let prev = document.location.pathname.split(`/`)
			if (!prev[prev.length - 1]) {
				prev.pop()
			}
			prev.pop()
			prev.push(`2`)
			navigate(prev.join(`/`))
		}
	}, [])

	return (
		<StringBuilderTemplate
			settings={settings}
			stepNumber={4}
			title='Choose your dampeners'
			enableNext={true}
		>
			<div css={styles.products}>
				{addOns.map((product, key) => (
					<Product product={product} key={key} />
				))}
				{settings.installation && !!settings.installation.title && !!settings.installation.shopifyPrice && (
					<Product product={{
						title: settings.installation.title,
						sku: settings.installation.sku,
						shopifyProductId: settings.installation.shopifyProductId,
						variants: [
							{
								price: settings.installation.shopifyPrice,
								sku: settings.installation.sku,
								shopifyVariantId: settings.installation.shopifyVariantId,
							},
						],
					}} />
				)}
			</div>
		</StringBuilderTemplate>
	)
}

const styles = {
	products: css`
		margin: 0 -10px;
		> *{
			padding: 0 10px;
		}
		@media(min-width:900px){
			display: flex;
			flex-wrap: wrap;
			> *{
				width: 50% !important;
			}
		}
	`,
}