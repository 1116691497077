import React, { useState, useEffect } from 'react'
import get from 'lodash/get'
import { css } from '@emotion/core'
import find from 'lodash/find'
import capitalize from 'lodash/capitalize'
import Img from '@app/responsive-image-sanity'
import { useUlysses } from '@smarterlabs/ulysses'
import imageBuilder from '@app/sanity-image-builder'
import useShopifyProduct from '@app/use-shopify-product'

export default function ProductBlock(props) {
	const product = props.product || {}
	const variants = get(product, `variants`, [])
	const [variant, setVariant] = useState(variants[0])
	const defaultImage = get(variant, `images.0`)
	const ulysses = useUlysses()
	const shopify = useShopifyProduct(product.shopifyProductId)
	const [isAvailable, setIsAvailable] = useState(props.mock ? true : false)

	// Get availability when selected variant changes
	useEffect(() => {
		let { available } = find(
			get(shopify, `data.variants`, []),
			{ id: variant.shopifyVariantId },
		) || {}
		setIsAvailable(available || false)
	}, [variant, shopify])

	let loadingProduct = shopify.status === `loading`

	let thumbnail
	if (defaultImage) {
		thumbnail = imageBuilder.image(defaultImage.asset).width(200).url()
	}

	let price = variant.price
	if (props.mock) {
		loadingProduct = false
		price = 99.99
	}

	// Change "add to cart" button text
	let buttonText
	if (loadingProduct) buttonText = `Checking inventory...`
	else if (ulysses.isLoading) buttonText = `Loading...`
	else if (isAvailable) buttonText = `Add to Cart`
	else buttonText = `Out of Stock`

	// Get a list of loop types
	const loopTypes = []
	// const loopColors = []
	if (product.productType === `stringLoop`) {
		variants.forEach(v => {
			const l = v.stringLoopVariant || {}

			if (loopTypes.indexOf(l.loopType) === -1) {
				loopTypes.push(l.loopType)
			}

			// const color = get(v)
		})
	}

	// Use first category for breadcrumb link
	const mainCategory = get(product, `categories.0`)
	const breadCrumbs = []
	if(mainCategory){
		breadCrumbs.push({
			link: {
				linkType: `internal`,
				internalLink: mainCategory,
			},
			title: mainCategory.title,
		})
	}

	return (
		<div>
			<div css={styles.container}>
				<div css={styles.image}>
					{!!defaultImage && (
						<Img asset={defaultImage.asset} alt={defaultImage.alt || product.title} />
					)}
					{product.productType === `stringLoop` && (
						<div>
							<div>

								<select
									css={styles.select}
									onChange={e => {
										const select =
											find(variants, {
												stringLoopVariant: {
													loopType: e.target.value,
													color: variant.color,
												},
											}) ||
											find(variants, {
												stringLoopVariant: {
													loopType: e.target.value,
												},
											})
										setVariant(select)
									}}
								>
									{loopTypes.map(type => {
										return (
											<option key={type} value={type}>
												{capitalize(type)}
											</option>
										)
									})}
								</select>
							</div>

							<div css={styles.colorButtonSet}>
								{variants.map(v => {
									const color = get(v, `stringLoopVariant.colorSwatch`, {})
									return (
										<button
											key={v._key}
											css={[styles.colorButton, v === variant && styles.activeColorButton]}
											onClick={e => {
												e.preventDefault()
												setVariant(v)
											}}
										>
											<span
												style={{ backgroundColor: get(color, `color.hex`) }}
											/>
											{color.colorName}
										</button>
									)
								})}
							</div>
						</div>

					)}
					{(product.productType === `multiColored`) && (
						<div css={styles.colorButtonSet}>
							{variants.map(v => {
								const color = get(v, `multiColoredVariant.colorSwatch`, {})
								return (
									<button
										key={v._key}
										css={[styles.colorButton, v === variant && styles.activeColorButton]}
										onClick={e => {
											e.preventDefault()
											setVariant(v)
										}}
									>
										<span
											style={{ backgroundColor: get(color, `color.hex`) }}
										/>
										{color.colorName}
									</button>
								)
							})}
						</div>
					)}
				</div>
				<div css={styles.content}>
					<h1 css={styles.productTitle}>{product.title}</h1>
					{(product.shopifyProductId || props.mock) && (
						<div css={styles.buy}>
							<h2>${price}</h2>
							<button
								disabled={!isAvailable}
								onClick={() => ulysses.addToCart({
									sku: product.sku,
									title: product.title,
									description: product.description,
									price: Number(price),
									shopifyId: variant.shopifyVariantId,
									image: thumbnail,
								})}
							>
								{buttonText}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

const styles = {
	container: css`
		margin: 30px 0;
		padding: 20px;
		background: #fff;
	`,
	colorButtonSet: css`
		/* display: flex; */
		text-align: right;
		margin-top: 20px;
	`,
	colorButton: css`
		display: inline-block;
		position: relative;
		color: transparent;
		width: 60px;
		height: 30px;
		min-width: 0;
		white-space: nowrap;
		overflow: hidden;
		user-select: none;
		margin: 5px;
		padding: 5px;
		border: 0;
		background: transparent !important;
		:hover, :focus{
			opacity: .6;
		}
		> *{
			position: absolute;
			top: 2px;
			right: 2px;
			bottom: 2px;
			left: 2px;
		}
	`,
	select: css`
		width: 100%;
      background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
        background-position: right 5px top;
        appearance: none;
		padding: 10px;
	`,
	activeColorButton: css`
		border: 1px solid #000;
	`,
	productTitle: css`
		margin: 30px 0 0 0;
		@media(min-width: 900px){
			margin: 0;
		}
	`,
	image: css`
		margin: auto;
		text-align: center;
	`,
	content: css`
		text-align: right;
		@media(min-width: 900px){
			text-align: left;
		}
	`,
	buy: css`
		> *{
			display: inline-block;
		}
		button{
			margin-left: 20px;
		}
		@media(min-width: 900px){
			> *{
				display: block;
				margin-top: 10px;
				margin-bottom: 0;
			}
			button{
				margin-left: 0;
			}
		}
	`,
}
